/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { css } from '@emotion/react';

import { TargetType } from '../../../../generated/global-types';
import { ReactComponent as ZippedIcon } from '../../../../images/icon_zipped.svg';
import { usePlayback } from '../../../../contexts/Playback';

import { ThemeType } from '../../../../theme';

import { getDimensionsFromTargetType, getHeader } from './helpers';
import {
  GetLockableUploadById_lockableUpload_LockableUpload_thumbnail_Upload,
  GetLockableUploadById_lockableUpload_LockableUpload_upload_Upload,
} from 'generated/GetLockableUploadById';
import { Screen_screen2_Screens2_plannedWorks_PlannedWorksConnection_nodes_PlannedWork_plannedWorkScreenMessages_PlannedWorkScreenMessagesConnection_nodes_PlannedWorkScreenMessage_upload_Upload } from 'generated/Screen';

const Screen: React.FC<
  { height: number; width: number; scale: number } & {
    children?: React.ReactNode;
  }
> = ({ height, width, scale, ...rest }) => (
  <main
    css={(theme: ThemeType) => css`
      box-sizing: border-box;
      height: calc(${height}px * ${scale});
      width: 100%;
      max-width: calc(${width}px * ${scale});
      border-radius: 4px;
      border: 1px solid ${theme.colors.border};
      overflow: hidden;
    `}
    {...rest}
  />
);

const Container: React.FC<
  { width: number; scale: number } & { children?: React.ReactNode }
> = ({ width, scale, ...rest }) => (
  <section
    css={css`
      width: 100%;
    `}
    {...rest}
  />
);

const Position: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
  ...rest
}) => (
  <header
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      font-weight: ${theme.typography.weights.bold};
      margin: 0 0 ${theme.spacing.xxsmall} 0;
    `}
    {...rest}
  >
    {children}
  </header>
);

const Info: React.FC<unknown & { children?: React.ReactNode }> = (props) => (
  <footer
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `}
    {...props}
  />
);

const ImagePreview: React.FC<{ src?: string | null }> = ({ src, ...rest }) => (
  <div
    role="img"
    css={css`
      height: 100%;
      width: 100%;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      ${src ? `background-image: url(${src});` : ''}
    `}
    {...rest}
  />
);

const Filename: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium}
    `}
    {...props}
  />
);

const DownloadLink: React.FC<
  {
    href: string | null | undefined;
    filename: string | null | undefined;
  } & { children?: React.ReactNode }
> = ({ filename, href, ...rest }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    download={filename ?? undefined}
    href={href ?? undefined}
    css={css`
      text-decoration: underline;
    `}
    {...rest}
  >
    Download
  </a>
);

const VideoPreview: React.FC<{ src?: string | null }> = ({ src, ...rest }) => {
  const video = React.useRef<HTMLVideoElement | null>(null);
  const { play, shouldBePlaying } = usePlayback(src ?? '');

  React.useEffect(() => {
    if (shouldBePlaying && video.current) {
      video.current?.play();
    }
    if (!shouldBePlaying && video.current) {
      video.current?.pause();
    }
  }, [video, shouldBePlaying]);

  return (
    <video
      ref={video}
      src={src ?? ''}
      controls
      muted
      loop
      title={shouldBePlaying ? 'Stop playback' : 'Start playback'}
      onClick={() => play()}
      css={css`
        height: 100%;
        width: 100%;
        cursor: pointer;
      `}
      {...rest}
    />
  );
};

const SmallText: React.FC<{ scale: number; children?: React.ReactNode }> = ({
  scale,
  ...props
}) => (
  <span
    css={(theme: ThemeType) => css`
      font-size: calc(56px * ${scale});
      font-weight: ${theme.typography.weights.bolder};
      text-align: center;
      text-transform: uppercase;
    `}
    {...props}
  />
);

const ZipContents: React.FC<{ scale: number }> = ({ scale, ...props }) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
    {...props}
  >
    <SmallText scale={scale}>Zipped File</SmallText>
    <ZippedIcon
      height={328 * scale}
      width={328 * scale}
      css={css`margin: calc(48px * ${scale}); 0;`}
    />
    <SmallText scale={scale}>Preview Unavailable</SmallText>
  </div>
);

const ZipFilePreview: React.FC<{ scale: number }> = ({ scale, ...props }) => (
  <div
    css={(theme: ThemeType) => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      background-color: ${theme.colors['background-inset']};
    `}
    {...props}
  >
    <ZipContents scale={scale} />
  </div>
);

interface InScreenPreviewProps {
  simple?: boolean;
  showCount?: boolean;
  showScreenCount?: boolean;
  scale?: number;
  zipContentsScale?: number;
  targetType: TargetType;
  setIndex?: number;
  totalScreens?: number;
  upload?: Screen_screen2_Screens2_plannedWorks_PlannedWorksConnection_nodes_PlannedWork_plannedWorkScreenMessages_PlannedWorkScreenMessagesConnection_nodes_PlannedWorkScreenMessage_upload_Upload | null;
}

const InScreenPreview: React.FC<InScreenPreviewProps> = ({
  targetType,
  setIndex = 0,
  upload,
  scale = 0.25,
  zipContentsScale = 0.25,
  simple = false,
  showCount = false,
  showScreenCount = false,
  ...rest
}) => {
  const [width, height] = getDimensionsFromTargetType(targetType);
  const dimensions = { width, height };
  const shouldShowPositionLabel = targetType === TargetType.TRIPTYCH_AD;
  const shouldShowDownloadLink = upload?.mimeType === 'application/zip';

  return (
    <Container width={dimensions.width} scale={scale} {...rest}>
      {!simple && shouldShowPositionLabel && (
        <Position>{getHeader(setIndex, 3)}</Position>
      )}
      {showCount && (
        <Position
          css={css`
            text-align: center;
          `}
        >
          {setIndex + 1}
        </Position>
      )}
      {showScreenCount && <Position>Screen {setIndex + 1}</Position>}
      <Screen {...{ ...dimensions, scale }}>
        {upload?.mimeType.startsWith('image/') && (
          <ImagePreview src={upload.signedS3Url} />
        )}
        {upload?.mimeType.startsWith('video/') && (
          <VideoPreview src={upload.signedS3Url} />
        )}
        {upload?.mimeType === 'application/zip' && (
          <ZipFilePreview scale={zipContentsScale ?? scale} />
        )}
      </Screen>
      {!simple && (
        <Info>
          <Filename>{upload?.originalFilename}</Filename>
          {shouldShowDownloadLink && (
            <DownloadLink
              href={upload?.signedS3Url}
              filename={upload?.originalFilename}
            />
          )}
        </Info>
      )}
    </Container>
  );
};

interface AsssetScreenPreviewProps {
  scale?: number;
  zipContentsScale?: number;
  upload: GetLockableUploadById_lockableUpload_LockableUpload_upload_Upload;
  thumbnail?: GetLockableUploadById_lockableUpload_LockableUpload_thumbnail_Upload | null;
}

export const LockedAssetScreenPreview: React.FC<AsssetScreenPreviewProps> = ({
  upload,
  scale = 0.25,
  zipContentsScale = 0.25,
  thumbnail = null,
  ...rest
}) => {
  const [url, setUrl] = React.useState('');

  React.useEffect(() => {
    // this is nuts. when data is refetched, the signed s3 url changes (query params specifically)
    // which probably explains why the browser won't cache the image.
    if ((!url && upload.signedS3Url) || !url.includes(upload.s3Url)) {
      setUrl(upload.signedS3Url!);
    }
  }, [upload.signedS3Url, upload.s3Url]);

  const hasThumbnail = !!thumbnail;
  const shouldShowDownloadLink = upload?.mimeType === 'application/zip';
  const width = upload.width ?? 0;
  const height = upload.height ?? 0;

  const Preview = () => {
    if (hasThumbnail) {
      return <ImagePreview src={url} />;
    }
    if (upload?.mimeType.startsWith('image/')) {
      return <ImagePreview src={url} />;
    }
    if (upload?.mimeType.startsWith('video/')) {
      return <VideoPreview src={url} />;
    }
    if (upload?.mimeType === 'application/zip') {
      return <ZipFilePreview scale={zipContentsScale ?? scale} />;
    }
    return <div />;
  };

  return (
    <div>
      {upload && (
        <Container width={width} scale={scale} {...rest}>
          <Screen {...{ width, height, scale }}>
            <Preview />
          </Screen>
          <Info>
            <Filename>{upload?.originalFilename}</Filename>
            {shouldShowDownloadLink && (
              <DownloadLink
                href={upload?.signedS3Url}
                filename={upload?.originalFilename}
              />
            )}
          </Info>
        </Container>
      )}
    </div>
  );
};

export default InScreenPreview;
