import getDay from 'date-fns/getDay';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

import {
  CAMPAIGNS_DURATIONS_REQUIRED,
  PLANNED_DURATIONS_REQUIRED,
  DURATIONS_EXPIRED,
  CAMPAIGN_DURATIONS_SAME,
  PLANNED_DURATIONS_SAME,
  RANGES_CANNOT_OVERLAP,
  CADENCES_CANNOT_OVERLAP,
  PLANNED_EMPTY_RANGES,
  PLANNED_EMPTY_CADENCES,
  CAMPAIGNS_EMPTY_RANGES,
  CAMPAIGNS_EMPTY_CADENCES,
  END_BEFORE_START,
  RANGE_DOES_NOT_INCLUDE_DAYS,
  SAME_DAY_CADENCE_END_BEFORE_START,
  EXCEPTIONS_CANNOT_OVERLAP,
  RANGE_DOES_NOT_INCLUDE_EXCEPTIONS,
  PLANNED_EMPTY_EXCEPTIONS,
  EXCEPTION_END_BEFORE_START,
  ACTIVE_SCHEDULED_ASSET_START_CHANGED_TO_PAST,
} from 'constants/error-messages';
import {
  DateRangeInput,
  DurationsSourceInput,
  DurationsSourceInputMethod,
} from 'generated/global-types';
import { GetDurationsFromDurationsSource_calculateDurationsFromDurationsSource_DatetimeRange as DurationType } from 'generated/GetDurationsFromDurationsSource';
import { getHoursMinutes } from 'components/common/complex-durations-selector';
import range from './range';

const NUM_DAYS_IN_WEEK = 7;

export enum Context {
  campaigns = 'campaigns',
  plannedWork = 'plannedWork',
  scheduledAssets = 'scheduledAssets',
}

// Given an array and a predicate function, returns a list of indices of the
// array which contain members who return true when passed to the predicate.
function indexWhere<T>(
  members: T[],
  predicate: (arg0: T, arg1?: number) => boolean,
): number[] {
  return members.reduce((indices, member, index) => {
    if (predicate(member, index)) {
      return indices.concat([index]);
    }

    return indices;
  }, [] as number[]);
}

// Determine if the range defined by (s1, e1) overlaps the range defined
// by (s2, e2). The boundaries of the ranges can be strings or numbers,
// though mixing types will lead to inconsistent results.
function overlaps<T = string | number>(s1: T, e1: T, s2: T, e2: T): boolean {
  // Sort all their boundaries and ensure that all the ends come immediately
  // after their respective starts. If they don't, one range starts or ends
  // inside the other. Arrays are converted to JSON for flexible equality checks
  // regardless of data type.
  const sortedBoundaries = [s1, e1, s2, e2].sort().join(',');

  return !(
    sortedBoundaries === [s1, e1, s2, e2].join(',') ||
    sortedBoundaries === [s2, e2, s1, e1].join(',')
  );
}

const expiredDurations = (input: DurationsSourceInput): number[] => {
  const now = new Date();

  switch (input.inputMethod) {
    case DurationsSourceInputMethod.DIRECT:
      return input.directDurations?.every(
        (d) => d?.end?.value && new Date(d.end.value) < now,
      )
        ? [-1]
        : [];
    case DurationsSourceInputMethod.CADENCE:
      now.setHours(0, 0, 0, 0);
      if (
        input.cadenceRange?.end?.value &&
        new Date(`${input.cadenceRange.end.value}T00:00:00`) < now
      ) {
        return [-1];
      }

      return [];
    default:
      return [];
  }
};

const sameDurations = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod === DurationsSourceInputMethod.DIRECT) {
    return indexWhere(
      input.directDurations ?? [],
      (d) =>
        d?.start?.value &&
        d?.end?.value &&
        new Date(d.start.value).toISOString() ===
          new Date(d.end.value).toISOString(),
    );
  }
  return [];
};

const incompleteDurationsSource = (input: DurationsSourceInput): number[] => {
  // eslint-disable-next-line default-case
  switch (input.inputMethod) {
    case DurationsSourceInputMethod.DIRECT:
      if (!input.directDurations?.length) {
        return [-1];
      }
      break;
    case DurationsSourceInputMethod.CADENCE:
      if (!input.cadenceRange?.start || !input.cadences?.length) {
        return [-1];
      }
      break;
  }

  return [];
};

const emptyRanges = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod !== DurationsSourceInputMethod.DIRECT) {
    return [];
  }

  return indexWhere(input.directDurations ?? [], (d) => !d?.start?.value);
};

const emptyCadences = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod !== DurationsSourceInputMethod.CADENCE) {
    return [];
  }

  return indexWhere(
    input.cadences ?? [],
    (c) =>
      typeof c.startDow !== 'number' ||
      !c.startTime ||
      typeof c.endDow !== 'number' ||
      !c.endTime,
  );
};

const rangeOverlaps = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod !== DurationsSourceInputMethod.DIRECT) {
    return [];
  }

  const durations = input.directDurations ?? [];

  return indexWhere(durations, (duration, index) =>
    durations.some(
      (d, i) =>
        i !== index &&
        overlaps(
          // We know these are strings or null, so we can set
          // fallback strings that we know will sort before or after
          // real values. If string, convert to ISO-8601 to ensure proper sorting.
          duration?.start?.value
            ? new Date(duration?.start?.value).toISOString()
            : '',
          duration?.end?.value
            ? new Date(duration?.end?.value).toISOString()
            : '9999',
          d?.start?.value ? new Date(d?.start?.value).toISOString() : '',
          d?.end?.value ? new Date(d?.end?.value).toISOString() : '9999',
        ),
    ),
  );
};

const cadenceOverlaps = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod !== DurationsSourceInputMethod.CADENCE) {
    return [];
  }

  const cadences = input.cadences ?? [];

  return indexWhere(cadences, (cadence, index) => {
    const cadenceEndDow =
      cadence?.startDow > cadence?.endDow
        ? cadence.endDow + NUM_DAYS_IN_WEEK
        : cadence.endDow;

    return cadences.some((c, i) => {
      const cEndDow =
        c?.startDow > c?.endDow ? c.endDow + NUM_DAYS_IN_WEEK : c.endDow;
      const doesOverlap =
        i !== index &&
        overlaps(
          // Convert these into sortable strings
          `${cadence?.startDow} ${getHoursMinutes(cadence?.startTime || '')}`,
          `${cadenceEndDow} ${getHoursMinutes(cadence?.endTime || '')}`,
          `${c?.startDow} ${getHoursMinutes(c?.startTime || '')}`,
          `${cEndDow} ${getHoursMinutes(c?.endTime || '')}`,
        );

      return doesOverlap;
    });
  });
};

const isEndBeforeStart = (d: DateRangeInput) => {
  return (
    !!d?.start?.value &&
    !!d?.end?.value &&
    new Date(Date.parse(d.end.value)) < new Date(Date.parse(d.start.value))
  );
};

const endBeforeStart = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod === DurationsSourceInputMethod.CADENCE) {
    if (input.cadenceRange && isEndBeforeStart(input.cadenceRange)) {
      return [-1];
    }

    return [];
  }

  const durations = input.directDurations ?? [];
  return indexWhere(durations, (d) => {
    return (
      !!d?.start?.value &&
      !!d?.end?.value &&
      new Date(Date.parse(d.end.value)) < new Date(Date.parse(d.start.value))
    );
  });
};

const rangeIncludesCadence = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod === DurationsSourceInputMethod.DIRECT) {
    return [];
  }

  if (!input.cadenceRange?.start?.value || !input.cadenceRange?.end?.value) {
    return [];
  }

  const daysInRange = eachDayOfInterval({
    start: new Date(Date.parse(input.cadenceRange.start.value)),
    end: new Date(Date.parse(input.cadenceRange.end.value)),
  })
    .slice(0, NUM_DAYS_IN_WEEK)
    .map((d) => getDay(d) + 1);

  if (daysInRange.length === NUM_DAYS_IN_WEEK) {
    return [];
  }

  const cadences = input.cadences ?? [];
  return indexWhere(cadences, (c) => {
    if (!c.startDow || !c.endDow) {
      return false;
    }

    const endDow =
      c.startDow > c.endDow ? c.endDow + NUM_DAYS_IN_WEEK : c.endDow;
    const cadenceAllDow = range(c.startDow, endDow);

    return !cadenceAllDow.every((dow) =>
      daysInRange.includes((dow % NUM_DAYS_IN_WEEK) as any),
    );
  });
};

const sameDayCadenceEndBeforeStart = (
  input: DurationsSourceInput,
): number[] => {
  if (input.inputMethod === DurationsSourceInputMethod.CADENCE) {
    const cadences = input.cadences ?? [];

    return indexWhere(cadences, (c) => {
      return (
        c.startDow === c.endDow &&
        parseInt(c.startTime.replace(':', ''), 10) >=
          parseInt(c.endTime.replace(':', ''), 10)
      );
    });
  }

  return [];
};

const rangeIncludesException = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod === DurationsSourceInputMethod.DIRECT) {
    return [];
  }

  const { start, end } = input.cadenceRange ?? {};
  const exceptions = input.exceptions ?? [];

  return indexWhere(exceptions, (e) => {
    const startDate = new Date(Date.parse(start?.value));
    const endDate = new Date(Date.parse(end?.value));

    return (
      e?.start?.value &&
      e?.end?.value &&
      (new Date(Date.parse(e.start.value)) <= startDate ||
        new Date(Date.parse(e.end.value)) >= endDate)
    );
  });
};

const exceptionOverlaps = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod === DurationsSourceInputMethod.DIRECT) {
    return [];
  }

  const exceptions = input.exceptions ?? [];

  return indexWhere(exceptions, (e, index) => {
    return exceptions.some((ex, i) => {
      const doesOverlap =
        i !== index &&
        overlaps(
          e?.start?.value || '',
          e?.end?.value || '9999',
          ex?.start?.value || '',
          ex?.end?.value || '9999',
        );

      return doesOverlap;
    });
  });
};

const emptyExceptions = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod === DurationsSourceInputMethod.DIRECT) {
    return [];
  }

  const exceptions = input.exceptions ?? [];

  return indexWhere(exceptions, (e) => {
    return !e?.start?.value || !e?.end?.value;
  });
};

const exceptionEndBeforeStart = (input: DurationsSourceInput): number[] => {
  if (input.inputMethod === DurationsSourceInputMethod.DIRECT) {
    return [];
  }

  const exceptions = input.exceptions ?? [];

  return indexWhere(exceptions, (e) => {
    return (
      !!e?.start?.value &&
      !!e?.end?.value &&
      new Date(Date.parse(e.end.value)) < new Date(Date.parse(e.start.value))
    );
  });
};

const startBeforeNow = (
  input: DurationsSourceInput,
  context?: Context,
): number[] => {
  if (
    input.inputMethod === DurationsSourceInputMethod.CADENCE ||
    context !== Context.scheduledAssets
  ) {
    return [];
  }
  const now = new Date();
  if (input.exceptions && input.directDurations) {
    const { start } = input.exceptions![0];
    const currentStart = input.directDurations![0].start;

    // cannot set an _active_ (start < now) assets start to be in the past
    if (start?.value! < now && currentStart?.value < now) {
      return [0];
    }

    // cannot set a _scheduled_ (start > now) asset start to be in the past
    if (start?.value > now && currentStart?.value < now) {
      return [0];
    }
  }

  return [];
};

export type DurationsSourceError = {
  message: string;
  indices?: {
    [DurationsSourceInputMethod.DIRECT]?: number[];
    [DurationsSourceInputMethod.CADENCE]?: number[];
  };
  isExceptionsError?: boolean;
};

// Validators have two parts: a function and a string. If the function
// determines that the durations source is invalid in some way, it must return
// a list of invalid indices. These indices refer to either `directDurations`
// or `cadences`, depending on the value of `inputMethod`. Returning an empty
// array signals that the validator's criteria have passed. If a function
// validates the durations source in a way that does not directly relate to one
// of the repeatable fields, it should return `[-1]`—that is, a non-empty array
// that does not specifically invalidate any particular field.
//
// The first validator that fails will result in its indices and corresponding
// message being returned. Subsequent validators will not be called.
const VALIDATORS: [
  (arg0: DurationsSourceInput, context?: Context) => number[],
  string | { [key: string]: string },
][] = [
  [
    incompleteDurationsSource,
    {
      [Context.plannedWork]: PLANNED_DURATIONS_REQUIRED,
      [Context.campaigns]: CAMPAIGNS_DURATIONS_REQUIRED,
    },
  ],
  [expiredDurations, DURATIONS_EXPIRED],
  [
    sameDurations,
    {
      [Context.plannedWork]: PLANNED_DURATIONS_SAME,
      [Context.campaigns]: CAMPAIGN_DURATIONS_SAME,
    },
  ],
  [
    emptyRanges,
    {
      [Context.plannedWork]: PLANNED_EMPTY_RANGES,
      [Context.campaigns]: CAMPAIGNS_EMPTY_RANGES,
    },
  ],
  [
    emptyCadences,
    {
      [Context.plannedWork]: PLANNED_EMPTY_CADENCES,
      [Context.campaigns]: CAMPAIGNS_EMPTY_CADENCES,
    },
  ],
  [
    startBeforeNow,
    {
      [Context.scheduledAssets]: ACTIVE_SCHEDULED_ASSET_START_CHANGED_TO_PAST,
    },
  ],
  [rangeOverlaps, RANGES_CANNOT_OVERLAP],
  [cadenceOverlaps, CADENCES_CANNOT_OVERLAP],
  [endBeforeStart, END_BEFORE_START],
  [rangeIncludesCadence, RANGE_DOES_NOT_INCLUDE_DAYS],
  [sameDayCadenceEndBeforeStart, SAME_DAY_CADENCE_END_BEFORE_START],
  [rangeIncludesException, RANGE_DOES_NOT_INCLUDE_EXCEPTIONS],
  [exceptionEndBeforeStart, EXCEPTION_END_BEFORE_START],
  [emptyExceptions, PLANNED_EMPTY_EXCEPTIONS],
  [exceptionOverlaps, EXCEPTIONS_CANNOT_OVERLAP],
];

const EXCEPTIONS_ERROR_MESSAGES = [
  RANGE_DOES_NOT_INCLUDE_EXCEPTIONS,
  EXCEPTIONS_CANNOT_OVERLAP,
  PLANNED_EMPTY_EXCEPTIONS,
  EXCEPTION_END_BEFORE_START,
];

export const validate = (
  input: DurationsSourceInput,
  context: Context,
): DurationsSourceError | null => {
  for (const [validator, messageInput] of VALIDATORS) {
    let isExceptionsError = false;

    if (EXCEPTIONS_ERROR_MESSAGES.includes(messageInput as string)) {
      isExceptionsError = true;
    }

    const indices = validator(input, context);

    if (indices?.length) {
      const message =
        typeof messageInput === 'string' ? messageInput : messageInput[context];

      return {
        message,
        indices: {
          [input.inputMethod]: indices,
        },
        isExceptionsError,
      };
    }
  }

  return null;
};

// The database expects that certain attributes of this type MUST be null,
// depending on the input method.
export const sanitize = (
  input: DurationsSourceInput,
  hrdOverrideText: string | null = null,
): DurationsSourceInput => {
  const direct = input.inputMethod === DurationsSourceInputMethod.DIRECT;

  return {
    inputMethod: input.inputMethod,
    directDurations: direct ? input.directDurations : null,
    cadenceRange: direct ? null : input.cadenceRange,
    cadences: direct ? null : input.cadences,
    untilFurtherNoticeMessage: input.untilFurtherNoticeMessage,
    humanReadableDurationsOverrideMessage: hrdOverrideText?.trim(),
    exceptions: direct ? null : input.exceptions,
  };
};

export const exceptionsCreatePartialDurations = (
  input: DurationsSourceInput,
  calculatedDurations: DurationType[],
): boolean => {
  if (input.inputMethod === DurationsSourceInputMethod.DIRECT) {
    return false;
  }

  const exceptions = input.exceptions ?? [];

  if (!exceptions.length) {
    return false;
  }

  return calculatedDurations.some((d) => {
    return exceptions.some((e) => {
      const durationStartDate = new Date(d.start?.value);
      const durationEndDate = new Date(d.end?.value);

      const exceptionStartDate = new Date(e.start?.value);
      const exceptionEndDate = new Date(e.end?.value);

      const doesOverlap =
        overlaps(
          d?.start?.value || '',
          d?.end?.value || '9999',
          e?.start?.value || '',
          e?.end?.value || '9999',
        ) &&
        !(
          exceptionStartDate <= durationStartDate &&
          exceptionEndDate >= durationEndDate
        );

      return doesOverlap;
    });
  });
};
